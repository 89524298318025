<template>
  <v-container>
    <div class="mt-3">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="800px"
      ></iframe>
    </div>
  </v-container>
</template>

<script>
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import moment from "moment";
import { mapState } from "vuex";
import base64 from "../../../services/base64";

export default {
  name: "print-preview-proforma",
  props: {
    id: Number,
  },
  computed: {
    ...mapState("external", ["contacts"]),
    ...mapState("auth", ["userDetail"]),
    total() {
      let total = 0;
      this.saleDownPayment.products.forEach(x => {
        total += x.total;
      });
      return total;
    },
  },
  data() {
    return {
      saleDownPayment: {},
      url: "",
    };
  },
  methods: {
    async preview() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleProforma/getById", this.id)
        .then(async response => {
          this.saleDownPayment = response.data;
          await this.$store.dispatch("external/getContact", response.data.customerId);
          this.createPdf();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    contactName(id) {
      const contact = this.contacts.find(x => x.id === id);
      const name = contact ? contact.name : "";
      return name;
    },
    setNewArray(items) {
      const array = [];
      items.forEach((x, index) => {
        if (items.length - 1 == index) {
          array.push([
            {
              content: index + 1,
              styles: { valign: "top", halign: "left" },
            },
            {
              content: x.partNumber,
              styles: { valign: "top", halign: "left" },
            },
            {
              content: x.mfr,
              styles: { valign: "top", halign: "left" },
            },
            {
              content:
                x.partDesc +
                " " +
                this.formatRemark(x.remark) +
                "\n\n\n\n\n\n" +
                this.formatBank(x.bank),
              styles: { valign: "top", halign: "left" },
            },
            {
              content: x.quantity + " " + x.unitMeasure,
              styles: { valign: "top", halign: "left" },
            },
            {
              content: this.formatPrice(x.unitPrice),
              styles: { valign: "top", halign: "right" },
            },
            {
              content: this.formatPrice(x.total),
              styles: { valign: "top", halign: "right" },
            },
          ]);
        } else {
          array.push([
            {
              content: index + 1,
              styles: { valign: "top", halign: "left" },
            },
            {
              content: x.partNumber,
              styles: { valign: "top", halign: "left" },
            },
            {
              content: x.mfr,
              styles: { valign: "top", halign: "left" },
            },
            {
              content: x.partDesc + " " + this.formatRemark(x.remark) + "\n",
              styles: { valign: "top", halign: "left" },
            },
            {
              content: x.quantity + " " + x.unitMeasure,
              styles: { valign: "top", halign: "left" },
            },
            {
              content: this.formatPrice(x.unitPrice),
              styles: { valign: "top", halign: "right" },
            },
            {
              content: this.formatPrice(x.total),
              styles: { valign: "top", halign: "right" },
            },
          ]);
        }
      });
      return array;
    },
    formatBank(bank) {
      if (bank) {
        let first = `ACCOUNT BANK :\n${bank.bankName}\nCABANG ${bank.branch},\n${bank.address}\nA/N : ${bank.ownerName}\n`;
        bank.items.forEach(x => {
          first = first + `NO : ${x.accountNumber}\n`;
        });
        let result =
          first + `SWIFT CODE : ${bank.swiftCode}\nPlease pay the full amount of this invoice`;
        return result;
      }
      return "";
    },
    formatRemark(remark) {
      if (remark) {
        return remark;
      }
      return "";
    },
    createPdf() {
      const doc = new jsPDF();
      let totalPagesExp = "{total_pages_count_string}";

      doc.autoTable({
        margin: { top: 97, left: 10 },
        theme: "plain",
        head: [["No", "Part#", "Mfr", "Description", "Qty", "Unit Price", "Line Total"]],
        body: [
          ...this.setNewArray(this.saleDownPayment.products),
          [
            {
              colSpan: 5,
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0 },
            },
            {
              content: "Sub Total :",
              styles: { valign: "middle", halign: "center", fontStyle: "bold" },
            },
            {
              content: this.formatPrice(this.total),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          [
            {
              colSpan: 5,
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0 },
            },
            {
              content: `PPN ${this.saleDownPayment.percentagePpn}%`,
              styles: { valign: "middle", halign: "center", fontStyle: "bold" },
            },
            {
              content: this.formatPrice(this.saleDownPayment.taxAmount),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          [
            {
              colSpan: 5,
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0 },
            },
            {
              content: `Total ${this.saleDownPayment.percentageInvoice}%`,
              styles: { valign: "middle", halign: "center", fontStyle: "bold" },
            },
            {
              content: this.formatPrice(this.saleDownPayment.totalAmountAfterTax),
              styles: { valign: "middle", halign: "right" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
          fontSize: 9,
          overflow: "linebreak",
          overflowColums: "linebreak",
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          fillColor: "#DDDDDD",
        },
        columnStyles: {
          0: { cellWidth: 8 },
          1: { cellWidth: 30 },
          2: { cellWidth: 25 },
          3: { cellWidth: 62 },
          4: { cellWidth: 12 },
          5: { cellWidth: 25 },
          6: { cellWidth: 25 },
          // etc
        },
        didParseCell: function(data) {
          if (data.section === "body" && data.column.index === 5 && data.row.raw.length === 3) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fillColor = "#DDDDDD";
          }
        },
        didDrawPage: () => {
          const item = this.saleDownPayment;
          let str = doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }

          doc.addImage(base64.logoUrl, "JPEG", 10, 10, 90, 20);

          doc.setFont("Times");
          doc.setFontSize(10);
          doc.text("INTERCON PLAZA BLOK D. 11", 10, 35);
          doc.text("JL. MERUYA ILIR, SRENGSENG-KEMBANGAN", 10, 40);
          doc.text("Phone   (62-21)7587-9949/51", 10, 45);
          doc.text("Fax     (62-21)7587-9952", 10, 50);

          doc.setFont("Times", "bold");
          doc.setFontSize(15);
          doc.text("PROFORMA INVOICE", 130, 45);
          doc.setFont("Times", "normal");
          doc.setFontSize(10);

          const tableY = 53;
          doc.rect(10, tableY, 90, 40);
          doc.rect(115, tableY + 5, 90, 30);

          doc.setFont("Times", "bold");
          doc.setFontSize(11);
          doc.text("CUSTOMER NAME", 12, tableY + 5);
          doc.setFontSize(10);
          doc.text(item.customerName, 12, tableY + 10);

          doc.setFont("Times", "normal");
          doc.setFontSize(10);
          let splitTitle = doc.splitTextToSize(item.customerAddress, 80);
          let size = doc.getTextDimensions(splitTitle);
          doc.text(splitTitle, 12, tableY + 15);
          doc.text(
            `Phone    ${item.phone === null ? "" : item.phone}`,
            12,
            tableY + 18 + Math.ceil(size.h)
          );
          doc.text(
            `fax      ${item.fax === null ? "" : item.fax}`,
            62,
            tableY + 18 + Math.ceil(size.h)
          );
          doc.setFont("Times", "bold");
          doc.setFontSize(10);
          doc.text(`Contact Name      ${this.contactName(item.billToContact)}`, 12, tableY + 35);

          doc.setFont("Times", "bold");
          doc.setFontSize(12);
          doc.text("P.Inv.No.", 117, tableY + 10);
          doc.text("P.Inv.Date.", 117, tableY + 15);
          doc.text("PO#", 117, tableY + 20);
          doc.text("Page No.", 117, tableY + 25);
          doc.setFont("Times", "normal");
          doc.setFontSize(10);
          doc.text(`: ${item.invoiceNumber}`, 147, tableY + 10);
          doc.text(`: ${moment(item.invoiceDate).format("DD-MMMM-yyyy")}`, 147, tableY + 15);
          doc.text(`: ${item.purchaseOrderNumber}`, 147, tableY + 20);
          doc.text(`: ${str}`, 147, tableY + 25);
        },
      });

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }

      const y = doc.lastAutoTable.finalY + 10;

      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text("PREPARED BY :", 20, y);
      doc.text("RECEIVED BY :", 90, y);
      doc.line(15, y + 25, 53, y + 25);
      doc.line(85, y + 25, 123, y + 25);

      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      doc.text("Note :", 10, y - 20);
      doc.text("WE WILL PROCESS THIS PO AFTER RECEIVE T/T PAYMENT", 25, y - 20, { maxWidth: 120 });

      this.url = doc.output("datauristring");
    },
  },
  created() {
    this.preview();
  },
};
</script>

<style></style>
